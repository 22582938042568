import React, { useEffect } from 'react'
import { isMobile } from "react-device-detect"
import { getBootstrap, getBootStatus } from "./features/bootstrapSlice"
import { setLat, setLng } from "./features/geolocationSlice"
import AppMobile from "./components/Mobile/AppMobile"
import AppMobile1 from "./components/Mobile1/AppMobile"
import AppMobile2 from "./components/Mobile2/AppMobile"
import { useSelector, useDispatch } from 'react-redux'
import { createBrowserHistory } from "history";
import useQuery from './functions/getParams'
import AppDesktop from './components/Desktop/AppDesktop';
// import setCookie from './functions/setCookie'
import getCookie from './functions/getCookie'

function App() {
    let query = useQuery();
    const history = createBrowserHistory();
    
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getBootstrap("/get-bootdata"))
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition( function (position) {
                dispatch(setLng({lng:position.coords.longitude}))
                dispatch(setLat({lat:position.coords.latitude}))
            });
        }
    }, [getCookie['master']]) 
    //setCookie('master', 445, 1)
    const bootState = useSelector(getBootStatus);

    //aggiorna dinamicamente favicon e title - white label
    useEffect(() => {
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement('link');
          link.rel = 'icon';
          document.getElementsByTagName('head')[0].appendChild(link);
        }
        link.href = bootState.data.favicon;
        document.title = bootState.data.title;
      }, [bootState.loading]);

    var pagine_solo_mobile = ["unsubscribe", "subscribe"];

    if(!bootState.loading){

        //modifica font per desio
        if(bootState.data.master === "1325" || bootState.data.master === "1539" || bootState.data.master === "1535" || bootState.data.master === "1537" || bootState.data.master === "1540" || bootState.data.master === "1550"){
            document.body.style.cssText += ";font-family:'Lato', sans-serif !important;";
        }else if(bootState.data.posto_master.font !== "") {
            document.body.style.cssText += bootState.data.posto_master.font;
        }else {
            document.body.style.cssText += ";font-family:'Inter', sans-serif !important;";
        }

        if(getCookie('kiosk') === 'on') {
            document.body.style.cssText += ";zoom: 200% !important;";
        }
        
        if(isMobile || history.location.pathname.match("/questionario/(.*)") || history.location.pathname.match("/anteprimaquestionario/(.*)") || history.location.pathname.match("/moduloreputazione/(.*)") || pagine_solo_mobile.includes(query.get('page')) || history.location.pathname.match("/(.*)/unsubscribe.html") || history.location.pathname.match("/(.*)/subscribe.html")){
            return ( 
                <div> 
                    {bootState.data.master !== "" || history.location.pathname.match("/questionario/(.*)") || history.location.pathname.match("/anteprimaquestionario/(.*)") || history.location.pathname.match("/moduloreputazione/(.*)") || pagine_solo_mobile.includes(query.get('page')) || history.location.pathname.match("/(.*)/unsubscribe.html") || history.location.pathname.match("/(.*)/subscribe.html") 
                    ?
                    <>
                        {(() => {
                            switch (bootState.data.posto_master.mobile) {
                                case "1":   return <AppMobile1 history={history}/> 
                                case "2":   return <AppMobile2 history={history}/> 
                                default:  return <AppMobile history={history}/> 
                            }
                        })()}
                    </>
                    : 
                        <></> 
                    }
                </div>
            )
        }else{
            return ( 
                <div> 
                    {bootState.data.master !== "" ? <AppDesktop /> : <></> }
                </div>
            )
        }
    }else {
        return ( 
            <></>
        )
    }

}

export default App;